import { useContext, useEffect, useRef, useState } from 'react';
import CookiesConsentContext from '@/contexts/CookiesConsentContext';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import useDisablePageScroll from '@/hooks/useDisablePageScroll';

type Window = typeof window & {
  HiJiffyWidget?: {
    Token?: string;
  };
};

export const ScriptsInjector = () => {
  const { getConsent, consents } = useContext(CookiesConsentContext);
  const { settings } = useContext(SettingsContext);
  const { currentLang } = useContext(LanguageContext);

  // Hijiffy

  const alreadyHijiffyInjected = useRef(false);
  const injectScript = () => {
    const _window: Window = window;
    _window.HiJiffyWidget = _window.HiJiffyWidget || {};
    _window.HiJiffyWidget.Token = 'PxghI0nHCxcwN1';

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = 'hijiffy-kustom';
    script.src =
      'https://widget.hijiffy.com/build.js?t=' +
      Math.floor(new Date().getTime() / 60000);
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (getConsent('hijiffy') && !alreadyHijiffyInjected.current) {
      alreadyHijiffyInjected.current = true;
      injectScript();
    }
  }, [consents, getConsent]);

  // GTM

  const alreadyGtmHeadInjected = useRef(false);
  const injectScriptHead = () => {
    const script = document.createElement('script');
    script.id = 'gtm-head';
    const inlineCode = document.createTextNode(
      settings?.googleServices?.googleTagManagerHeaderTag || '',
    );
    script.appendChild(inlineCode);
    document.head.appendChild(script);
  };

  const alreadyGtmBodyInjected = useRef(false);
  const injectScriptEndOfBody = () => {
    const script = document.createElement('div');
    script.id = 'gtm-body';
    const inlineCode = settings?.googleServices?.googleTagManagerBodyTag || '';
    script.innerHTML = inlineCode;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!alreadyGtmHeadInjected.current) {
      alreadyGtmHeadInjected.current = true;
      injectScriptHead();
    }
    if (!alreadyGtmBodyInjected.current) {
      alreadyGtmBodyInjected.current = true;
      injectScriptEndOfBody();
    }
  }, []);

  // HSW

  const version = '?v=8';

  const alreadyHswInjected = useRef<string | null>(null);
  const injectScriptHotelSmartWidgets = (
    currentLang: LanguageContextKey,
    id: string,
    domain: string,
  ) => {
    if (alreadyHswInjected.current === currentLang) return;
    alreadyHswInjected.current = currentLang;

    const lang = currentLang === 'fr' ? '' : `-${currentLang}`;
    const currentScript = document.getElementById('diadao-hsw-dist-script-js');
    const currentStyle = document.getElementById('diadao-hsw-dist-style-css');
    const currentHtml = document.getElementById('diadao-hsw-hsp-html');

    if (currentScript) currentScript.remove();
    if (currentStyle) currentStyle.remove();
    if (currentHtml) currentHtml.remove();

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = 'diadao-hsw-dist-script-js';
    script.src = `${domain}/hsw-${id}${lang}.js${version}`;

    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = 'diadao-hsw-dist-style-css';
    link.href = `${domain}/hsw-${id}${lang}.css${version}`;
    link.media = 'all';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);
  };

  // const [isOpenHsw, setIsOpenHsw] = useState(false);

  // useDisablePageScroll(isOpenHsw);

  useEffect(() => {
    const id = settings?.modules?.extraFields?.hotelSmartWidgetsId?.value;
    const domain =
      settings?.modules?.extraFields?.hotelSmartWidgetsDomain?.value;

    if (id && domain) {
      setTimeout(() => {
        injectScriptHotelSmartWidgets(currentLang, id, domain);
      }, 2000);
    }

    // window.addEventListener('smartpreviewPanelOpened', () => {
    //   setIsOpenHsw(true);
    // });

    // window.addEventListener('smartpreviewPanelClosed', () => {
    //   setIsOpenHsw(false);
    // });

    window.addEventListener('click', (e) => {
      if (e.target instanceof HTMLElement) {
        const isHspClick = e.target?.closest('#smartpreview');
        const isAxeptioClick = e.target?.closest('#axeptio_overlay');
        if (isHspClick || isAxeptioClick) {
          e.stopPropagation();
        } else {
          window.dispatchEvent(new CustomEvent('smartpreviewPanelClose'));
        }
      }
    });

    // return () => {
    //   window.removeEventListener('smartpreviewPanelOpened', () => {
    //     setIsOpenHsw(true);
    //   });

    //   window.removeEventListener('smartpreviewPanelClosed', () => {
    //     setIsOpenHsw(false);
    //   });
    // };
  }, [currentLang]);

  return null;

  // return (
  //   <div
  //     ref={testRef}
  //     id="smartpreview"
  //     style={{
  //       position: 'fixed',
  //       width: '300px',
  //       left: 0,
  //       top: 0,
  //       background: '#fff',
  //       zIndex: 9999,
  //     }}
  //   >
  //     -
  //   </div>
  // );
};

/*
export default async function buildOffers(lang = 'fr', container) {
  // try {
  const data = await fetch(`/api/offers?lang=${lang}`);
  const offers = await data.json();

  if (!offers || offers.length < 1) return;

  offers.forEach(
    ({
      image = '',
      type = '',
      title = '',
      price = null,
      currency = 'EUR',
      discount = null,
    }) => {
      const offerDiv = document.createElement('div');
      offerDiv.id = '123';
      offerDiv.className = 'smartpreview-offres__object';

      const imageContainer = document.createElement('div');
      imageContainer.className = 'smartpreview-offres__object__img';
      offerDiv.appendChild(imageContainer);

      // Image

      const picture = document.createElement('picture');
      picture.className = 'bg-image';
      const source = document.createElement('source');
      source.srcset = image;
      const pictureImg = document.createElement('img');
      pictureImg.src = image;
      pictureImg.style.objectFit = 'cover';
      picture.appendChild(source);
      picture.appendChild(pictureImg);
      offerDiv.appendChild(picture);
      imageContainer.appendChild(picture);

      if (type && type !== '') {
        const typeDiv = document.createElement('div');
        typeDiv.className =
          'smartpreview-offres__object__category smartpreview-offres__object__category--default';
        typeDiv.innerText = type;
        imageContainer.appendChild(typeDiv);
      }

      // Title

      const textContainer = document.createElement('div');
      textContainer.className = 'smartpreview-offres__object__content';

      const titleContainer = document.createElement('div');
      titleContainer.className = 'smartpreview-offres__object__title';
      titleContainer.innerText = title;
      textContainer.appendChild(titleContainer);

      // Price wrapper

      if (price) {
        const priceContainer = document.createElement('div');
        priceContainer.className = 'smartpreview-offres__object__price';
        const priceWrapper = document.createElement('span');
        priceWrapper.className = 'price-wrapper';
        priceContainer.appendChild(priceWrapper);

        // From

        if (price.isFrom && price.fromText && price.fromText !== '') {
          const prefix = document.createElement('span');
          prefix.className = 'prefix';
          prefix.innerHTML = price.fromText;
          priceWrapper.appendChild(prefix);
        }

        const priceEl = document.createElement('span');
        priceEl.className = 'price';
        priceWrapper.appendChild(priceEl);

        // Old price

        if (discount.active && discount?.value && discount?.value !== '') {
          const oldPrice = document.createElement('div');
          oldPrice.className = 'old-price';

          const oldPriceWrapper = document.createElement('div');
          oldPriceWrapper.className = 'old-price__wrapper';
          oldPrice.appendChild(oldPriceWrapper);

          const oldPriceWrapperBefore =
            oldPriceWrapper.querySelector('::before');
          if (oldPriceWrapperBefore)
            oldPriceWrapperBefore.style.display = 'none';

          if (discount.isPercentage) {
            const oldPriceValue = document.createElement('span');
            oldPriceValue.className = 'value';
            oldPriceValue.innerHTML = '-' + discount.value + '%';
            oldPriceValue.style.fontSize = '14px';
            oldPriceValue.style.lineHeight = '1';
            oldPriceWrapper.appendChild(oldPriceValue);
          } else {
            oldPriceWrapper.style.textDecoration = 'line-through';

            const oldPriceValue = document.createElement('span');
            oldPriceValue.className = 'value';
            oldPriceValue.innerHTML = discount.value;
            oldPriceValue.style.fontSize = '14px';
            oldPriceValue.style.lineHeight = '1';
            oldPriceWrapper.appendChild(oldPriceValue);

            const oldPriceCurrency = document.createElement('span');
            oldPriceCurrency.className = 'currency';
            oldPriceCurrency.innerHTML = ' ' + currency;
            oldPriceCurrency.style.fontSize = '14px';
            oldPriceCurrency.style.lineHeight = '1';
            oldPriceWrapper.appendChild(oldPriceCurrency);
          }

          priceEl.appendChild(oldPrice);
        }

        // Price

        if (price.active && price?.value && price?.value !== '') {
          const priceValue = document.createElement('span');
          priceValue.className = 'value';
          priceValue.innerHTML = price.value;
          priceEl.appendChild(priceValue);

          const priceCurrency = document.createElement('span');
          priceCurrency.className = 'currency';
          priceCurrency.innerHTML = ' ' + currency;
          priceEl.appendChild(priceCurrency);

          if (price?.detail && price?.detail !== '') {
            const priceBy = document.createElement('span');
            priceBy.className = 'by';
            priceBy.innerHTML = ' ' + price.detail;
            priceEl.appendChild(priceBy);
          }
        }

        textContainer.appendChild(priceContainer);
      }

      offerDiv.appendChild(textContainer);
      // container.css('display', 'block');

      // JQUERY
      // container.append(offerDiv);

      // VANILLA JS
      container.appendChild(offerDiv);
    },
  );
  // } catch (error) {
  //   console.log('HSP -> Failed to fetch and build offers', error);
  // }
}
*/
