import Image from 'next/image';

import {
  CustomComponentData,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';

import { Box, Container, SlideFade, keyframes } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useDelay from '@/hooks/useDelay';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import Logo from '@/../public/assets/img/logo/logo-4.svg';
import { Parallax } from 'react-scroll-parallax';
import useIsMounted from '@/hooks/useIsMounted';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Hero: React.FC<KustomPageComponentViewProps<CustomComponentData>> = ({
  component,
  page,
}) => {
  const mediaImgIndex = component.data?.atomicComponents.findIndex(
    (cmp) => cmp.type === 'MEDIA',
  ) as number;

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.atomicComponents.${mediaImgIndex}.media`,
  );

  const [textIndex1] = component.data?.atomicComponents.reduce(
    (acc, cmp, index) => (cmp.type === 'TEXT' ? [...acc, index] : acc),
    [] as number[],
  );

  const text1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex1}.text`,
  );

  const delayIndex = useDelay(300, 5);

  const isMounted = useIsMounted();

  const backgroundImgEl = useRef<HTMLDivElement>(null);
  function fadeBackgroundImg(e: Event) {
    const progress = (window.scrollY / window.innerHeight) * 1.75;
    backgroundImgEl.current?.style.setProperty(
      'opacity',
      progress < 1 ? String(1 - progress) : '0',
    );
    if (progress > 0.99) {
      backgroundImgEl.current?.style.setProperty('pointer-events', 'none');
    } else {
      backgroundImgEl.current?.style.setProperty('pointer-events', 'auto');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', fadeBackgroundImg);
    return () => {
      window.removeEventListener('scroll', fadeBackgroundImg);
    };
  }, []);

  const device = useResponsiveMediasDevice();

  return (
    <Box
      h="100svh"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflowX="clip"
      className="hero"
    >
      <Box
        ref={backgroundImgEl}
        w="100vw"
        h="100%"
        maxH="100svh"
        position="fixed"
        top={0}
        left={0}
        right={'var(--display-page-scroll-shift, 0)'}
        alignSelf="flex-start"
        background={'brand.600'}
        overflow={'hidden'}
        opacity={1}
        pointerEvents={'none'}
        sx={{
          '.chakra-popover__popper': {
            top: '50% !important',
            transform: 'translate(10%, -50%) !important',
          },
        }}
      >
        <Box
          w="100%"
          h="100%"
          position="absolute"
          zIndex={2}
          left={0}
          top={0}
          background={'rgba(0,0,0,0.3)'}
          boxShadow={'inset 0 0 10rem rgba(0,0,0,0.1)'}
          pointerEvents={'none'}
        />
        {mediaBind.value && (
          <ResponsiveMedias
            skeleton={false}
            {...mediaBind}
            disableCSSAdminWysiwyg
            width={undefined}
            height={undefined}
            fill
            currentDevice={device}
            quality={50}
            style={{
              objectFit: 'cover',
              background: 'brand.600',
            }}
            // sizes="(max-width: 959px) 300vw, 100vw"
            sizes={chakraResponsiveToImageSizes([
              '300vw',
              null,
              '200vw',
              '100vw',
            ])}
          />
        )}
      </Box>
      <Container
        maxW="container.lg"
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        {isMounted && (
          <Box position="relative" mx={10}>
            <Parallax
              speed={15}
              opacity={[1, 0]}
              translateY={['0', '-100%']}
              startScroll={0}
              endScroll={
                window.innerHeight * (device === 'mobile' ? 0.55 : 0.75)
              }
            >
              <SlideFade
                offsetY="10%"
                in={delayIndex > 2}
                transition={{
                  enter: {
                    duration: 1,
                    easing: 'easeInOut',
                  },
                }}
              >
                <Logo
                  style={{
                    width: 'max(13vw, 150px)',
                    color: 'white',
                    filter: 'drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.1)',
                  }}
                />
              </SlideFade>
            </Parallax>
          </Box>
        )}
      </Container>

      {isMounted && (
        <Box
          position="absolute"
          m="1rem"
          mb="3rem"
          bottom="0"
          animation={`${keyframes`from {opacity: 0} to {opacity: 1}`} 0.5s ease-out 750ms 1 both`}
        >
          <Parallax
            speed={15}
            opacity={[1, 0]}
            translateY={['0', '0']}
            startScroll={0}
            endScroll={window.innerHeight * 0.25}
          >
            <RichText
              as="h1"
              fontSize="1rem"
              fontFamily="commutersSansBold"
              textAlign="center"
              color="white"
              {...text1Bind}
            />
          </Parallax>
        </Box>
      )}
    </Box>
  );
};

export default Hero;
