import { Box, Button, Link, Portal, Text } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  RoomBookingComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React, { useContext } from 'react';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import WrapIf from '../atomics/WrapIf';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useMewsButton from '@/hooks/useMews';
import { ContactDiadao } from '../ContactDiadao';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';

const RoomBooking: React.FC<
  KustomPageComponentViewProps<RoomBookingComponentData>
> = (props) => {
  const bpIndex = useBreakpointIndex();

  const toBookLabelBind = useKustomTranslation('to_book');

  // const $mewsButtonProps = useMewsButton();
  const settingsCtx = useContext(SettingsContext);
  const bookingUrl = settingsCtx?.settings?.bookingEngine?.enginePublicLink;

  return (
    <WrapIf
      condition={bpIndex < 3}
      wrapper={(children) => <Portal>{children}</Portal>}
    >
      <Box
        justifyContent="center"
        display="flex"
        justifyItems="space-between"
        alignItems="center"
        w="100%"
        style={
          bpIndex < 3
            ? {
                position: 'fixed',
                zIndex: 2,
                flexDirection: 'row',
                gap: '1rem',
                bottom: 0,
                right: 0,
                left: 0,
                background: 'white',
                boxShadow: '0 0 1rem rgba(0, 0, 0, 0.05)',
                justifyContent: 'flex-start',
                padding: '0 clamp(1rem, 6vw, 7rem)',
              }
            : {
                flexDirection: 'column',
                position: 'sticky',
                top: 'calc(clamp(1rem, 6vw, 7rem) + 5rem)',
              }
        }
      >
        <Box
          p={[6, 8, null, null, 10]}
          bgColor="white"
          style={
            bpIndex < 3
              ? {
                  // flexDirection: 'row',
                  paddingLeft: '0',
                  paddingRight: '0',
                }
              : {
                  // flexDirection: 'column',
                  width: '100%',
                  borderRadius: '0.375rem',
                  boxShadow: '0 0 1rem rgba(0, 0, 0, 0.05)',
                }
          }
        >
          <Link href={bookingUrl} target="_blank">
            <Button
              colorScheme="brand"
              variant="button"
              // {...$mewsButtonProps}
              onClick={() => {}}
              w={[null, null, null, '100%']}
            >
              <TranslatedString color="inherit" {...toBookLabelBind} />
            </Button>
          </Link>
        </Box>
        <ContactDiadao
          pt={[null, null, null, 6]}
          display={['none', 'block']}
          flexGrow={1}
        />
      </Box>
    </WrapIf>
  );
};

export default RoomBooking;
