import Link from 'next/link';
import {
  ActionButtonComponent,
  KustomTranslatedString,
  ExtraFields,
  KustomPage,
  CustomComponentData,
  KustomPageComponent,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Button, chakra, IconButton, keyframes } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';

import { $kustomAdminBarHeightVarName } from '@/lib/kustomcms-sdk/lib/admin/components/KustomAuthModal';
import IconButtonSwitchIcon from './atomics/IconButtonSwitchIcon';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

import Logo from '@/../public/assets/img/logo/logo-3.svg';
import ReservationDrawer from './ReservationDrawer';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useDelay from '@/hooks/useDelay';
import useDisablePageScroll from '@/hooks/useDisablePageScroll';
import useKustomData, {
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import usePage from '@/lib/kustomcms-sdk/lib/hooks/usePage';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useRouter } from 'next/router';

import { IconSvg } from './IconSvg';

export const TOPBAR_HEIGHT = 100;

const ChakraLink = chakra(Link);

type Languages = {
  [key: string]: { label: string };
  en: { label: string };
  fr: { label: string };
  es: { label: string };
  de: { label: string };
};

const langs: Languages = {
  fr: { label: 'Français' },
  en: { label: 'English' },
  es: { label: 'Español' },
  de: { label: 'Deutsch' },
};

type MenuProps = {
  pageCode?: string;
};

const Menu = ({ pageCode }: MenuProps) => {
  const router = useRouter();
  const { locale, locales } = router;

  const [isOpen, setIsOpen] = useState(false);

  const [isMenuMixBlended, setIsMenuMixBlended] = useState(false);
  const [isNetworkMixBlended, setIsNetworkMixBlended] = useState(
    pageCode === '4onpu' ? false : true,
  );
  const [isReservationOpen, setIsReservationOpen] = useState(false);

  const menuModalContainerRef = useRef<HTMLDivElement>(null);

  const menuPage = usePage('kfpqg8');

  const menuCmp = usePageComponent<CustomComponentData>(
    menuPage,
    null,
    '17120725552934378',
  );
  const menu = menuCmp?.data?.atomicComponents.filter(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  ) as ActionButtonComponent[];

  const extraMenuCmp = usePageComponent<CustomComponentData>(
    menuPage,
    null,
    '17122185487023395',
  );
  const extraMenu = extraMenuCmp?.data?.atomicComponents.filter(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  ) as ActionButtonComponent[];

  const settingsCtx = useContext(SettingsContext);

  const networksData = settingsCtx?.settings?.socialNetworks;
  type PlatformData = {
    name: string;
    url: string;
  };
  function transformDataToObject(data: {
    [key: string]: string | boolean | ExtraFields;
  }): PlatformData[] {
    const end: PlatformData[] = [];
    const platforms = ['facebook', 'instagram', 'twitter'];
    platforms.forEach((platform) => {
      if (!data[`${platform}IsActivated`]) return;
      end.push({
        name: platform,
        url: data[`${platform}Url`] as string,
      });
    });
    return end;
  }
  const networks = networksData ? transformDataToObject(networksData) : [];

  const giftUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetGiftUrl']?.value;

  const closeReservation = () => {
    setIsReservationOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      menuModalContainerRef.current?.scrollTo(0, 0);
    }
  }, [isOpen]);

  useScrollPosition(({ currPos }) => {
    setIsMenuMixBlended(-currPos.y > 10);
    setIsNetworkMixBlended(-currPos.y > 50 || pageCode !== '4onpu');
  });

  useEffect(() => {
    if (pageCode) {
      setIsNetworkMixBlended(pageCode === '4onpu' ? false : true);
    }
  }, [pageCode]);

  const delayIndex = useDelay(1000, 2);

  const breakpointIndex = useBreakpointIndex();

  useDisablePageScroll(isOpen || isReservationOpen);

  const toBookLabelBind = useKustomTranslation('to_book');

  const open = (itemLabel?: KustomTranslatedString) => {
    setIsOpen(true);
    setIsOpenLangsMenu(false);
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const isActive = isOpen || isMenuMixBlended;

  const [isOpenLangsMenu, setIsOpenLangsMenu] = useState(false);

  if (!menuPage) return null;

  return (
    <>
      {/* BLACK OVERLAY */}

      <Box
        position="fixed"
        zIndex={100}
        top="0"
        right="0"
        left="0"
        bottom="0"
        bgColor="black"
        width={'100%'}
        height={'100svh'}
        onClick={close}
        opacity={[isOpen ? 0.5 : 0, null, null, null, 0]}
        // onMouseEnter={close}
        transition={isOpen ? 'opacity 0.3s 0.3s' : 'opacity 0.3s'}
        className="overlay"
        pointerEvents={isOpen ? 'auto' : 'none'}
      ></Box>

      {/* SOCIAL NETWORKS */}

      {networks.length > 0 && (
        <Box
          className="networks"
          position="fixed"
          display={['none', null, 'flex']}
          left={[5, null, 7]}
          bottom={[5, null, 7]}
          color={isNetworkMixBlended ? 'brand.500' : 'brand.50'}
          animation={`${keyframes`from {opacity: 0} to {opacity: 1}`} 0.5s ease-out 750ms 1 both`}
        >
          {networks.map((slide) => (
            <Link href={slide.url} target={'_blank'} key={slide.name}>
              <Box
                width={['2.5rem', null, '3rem']}
                height={['2.5rem', null, '3rem']}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <IconSvg
                  icon={slide.name.toLocaleLowerCase()}
                  size={['1.5rem', null, '1.75rem']}
                />
              </Box>
            </Link>
          ))}
        </Box>
      )}

      {/* TOPBAR */}

      <Box
        zIndex={102}
        className="main-topbar"
        position="fixed"
        top={`var(${$kustomAdminBarHeightVarName}, 0)`}
        h={TOPBAR_HEIGHT}
        py={[4, null, 6]}
        pl={[4, null, 6]}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        bgColor={isMenuMixBlended ? 'white' : undefined}
        boxShadow={isMenuMixBlended && !isOpen ? 'sm' : undefined}
        height={['80px', null, '100px']}
        transition="background-color 0.3s"
        pr={[
          'calc(1.25rem + var(--display-page-scroll-shift))',
          null,
          'calc(1.75rem + var(--display-page-scroll-shift))',
        ]}
        animation={`${keyframes`from {opacity: 0} to {opacity: 1}`} 0.5s ease-out 500ms 1 both`}
        // borderColor={['red', 'blue', 'green', 'orange', 'cyan']}
      >
        <Box display="flex" alignItems="center">
          {/* MOBILE BURGER ICON */}

          <IconButton
            display={'flex'}
            aria-label="Open menu"
            colorScheme="brand"
            bgColor={'transparent'}
            width={['2.5rem', null, '3rem']}
            height={['2.5rem', null, '3rem']}
            onClick={toggle}
            color={isActive ? 'brand.500' : 'white'}
            _hover={{
              bgColor: 'none',
            }}
            icon={
              isOpen ? (
                <IconSvg icon="cross" size={['1.5rem', null, '1.75rem']} />
              ) : (
                <IconSvg icon="menu" size={['1.5rem', null, '1.75rem']} />
              )
            }
            alignItems="center"
            justifyContent="center"
          />

          {locales && locales?.length > 1 && (
            <Box position={'relative'} ml={4}>
              <Button
                fontFamily="body"
                fontSize={'1rem'}
                px={4}
                py={6}
                fontWeight="700"
                _hover={{
                  bgColor: 'transparent',
                }}
                bgColor={'transparent'}
                color={isActive ? 'brand.500' : 'white'}
                transition="all 0.3s"
                onClick={() => {
                  setIsOpenLangsMenu(!isOpenLangsMenu);
                }}
                role="group"
              >
                {locale?.toUpperCase()}
              </Button>
              <Box
                position={'absolute'}
                background={isOpen ? 'brand.500' : 'white'}
                px={6}
                py={4}
                color={isOpen ? 'white' : 'brand.500'}
                display={'flex'}
                flexDirection={'column'}
                borderRadius={'4px'}
                opacity={isOpenLangsMenu ? '1' : '0'}
                pointerEvents={isOpenLangsMenu ? 'auto' : 'none'}
                transition={'opacity ease 200ms'}
                boxShadow="sm"
                sx={{
                  '& > *:not(:first-child)': {
                    borderTop: '1px solid',
                    borderColor: `${isOpen ? 'brand.400' : 'brand.300'}`,
                  },
                }}
              >
                {locales.map((lang, index) => (
                  <>
                    {lang !== locale ? (
                      <ChakraLink
                        href={'/'}
                        locale={lang}
                        key={lang}
                        p={2}
                        onClick={() => setIsOpenLangsMenu(false)}
                      >
                        {langs?.[lang]?.label || lang}
                      </ChakraLink>
                    ) : null}
                  </>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {isActive && (
          <Box
            height="100%"
            position="absolute"
            left={'calc(50% - (var(--display-page-scroll-shift) / 2))'}
            transform={'translateX(-50%)'}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={0}
            color="accent.500"
            animation={`${keyframes`from {opacity: 0} to {opacity: 1}`} 0.3s ease-in-out 0s 1 both`}
          >
            <Link
              href={'/'}
              onClick={() => {
                close();
              }}
              style={{
                padding: '1rem',
              }}
            >
              {isActive && (
                <Logo
                  style={{
                    width: '3.5rem',
                    height: '3.5rem',
                  }}
                />
              )}
            </Link>
          </Box>
        )}
        <Box mt={[null, null, 0.5]}>
          <IconButtonSwitchIcon
            id="diadao-button-gift"
            aria-label="gift"
            colorScheme="brand"
            bgColor="rgba(255,255,255,0.8)"
            mr={[2, null, 3]}
            width={['2.5rem', null, '3rem']}
            height={['2.5rem', null, '3rem']}
            color={'brand.500'}
            _hover={{
              bgColor: isActive ? 'brand.600' : 'brand.500',
              color: 'white',
            }}
            onClick={() => window.open(giftUrl, '_blank')}
            icon={<IconSvg icon="gift" size={['1.5rem', null, '1.75rem']} />}
            border={'1px solid'}
            borderColor={isActive ? 'brand.500' : 'transparent'}
          />
          {breakpointIndex > 1 ? (
            <Button
              id="diadao-button-booking"
              colorScheme="brand"
              fontFamily="body"
              fontSize={['0.875rem', null, null, null, null]}
              px={6}
              py={6}
              fontWeight="700"
              _hover={{
                bgColor: isActive ? 'brand.600' : 'brand.500',
                color: 'white',
              }}
              bgColor={isActive ? undefined : 'rgba(255,255,255,0.8)'}
              color={isActive ? undefined : 'brand.500'}
              transition="all 0.3s"
              onClick={() => {
                setIsReservationOpen(true);
                // close();
              }}
              role="group"
            >
              <TranslatedString color={'inherit'} {...toBookLabelBind} />
            </Button>
          ) : (
            <IconButtonSwitchIcon
              id="diadao-button-booking"
              aria-label="booking"
              colorScheme="brand"
              bgColor={isActive ? undefined : 'rgba(255,255,255,0.8)'}
              color={isActive ? 'white' : 'brand.500'}
              width={['2.5rem', null, '3rem']}
              height={['2.5rem', null, '3rem']}
              py={[0, null, 6]}
              fontSize={['22px', null, null, null, null, '24px']}
              fontWeight="bold"
              _hover={{
                bgColor: isActive ? 'brand.100' : 'brand.500',
                color: isActive ? 'brand.500' : 'white',
              }}
              onClick={() => {
                setIsReservationOpen(true);
                // close();
              }}
              icon={
                <IconSvg icon="calendar" size={['1.5rem', null, '1.75rem']} />
              }
            />
          )}
        </Box>
      </Box>

      {/* MENU CONTENT */}

      <Box
        zIndex={101}
        className="main-menu-container"
        position={'fixed'}
        top="0"
        w={'100%'}
        ref={menuModalContainerRef}
        bg="white"
        // opacity={[isOpen ? 1 : 0, null, null, null, 0]}
        opacity={isOpen ? 1 : 0}
        transition={isOpen ? 'opacity 0.7s' : 'none'}
        height={[isOpen ? '100%' : 'auto', null, 'auto']}
      >
        <Box
          className="menu-modal-content"
          // display={[isOpen ? 'block' : 'none', null, null, null, 'none']}
          display={isOpen ? 'block' : 'none'}
          w={'100%'}
          boxShadow="sm"
          overflowX="hidden"
          position="relative"
          flexDir="column"
          overflowY={['auto', null, null, 'auto']}
          marginTop={[
            `calc(79px + var(${$kustomAdminBarHeightVarName}, 0px))`,
            null,
            `calc(100px + var(${$kustomAdminBarHeightVarName}, 0px))`,
          ]}
          maxH={['calc(100svh - 79px)', null, 'calc(100svh - 150px)']}
          height={['100%', null, 'min(100svh, 700px)']}
        >
          <Box
            position="relative"
            display="flex"
            minH="100%"
            flexDir="column"
            justifyContent="space-between"
            overflow="hidden"
            pt="6"
          >
            <Box>
              {menuCmp &&
                menu?.map((item, index) => (
                  <MenuItem
                    key={index}
                    page={menuPage}
                    component={menuCmp}
                    index={index}
                    isActive={isActive}
                    isInModal={true}
                    onClose={close}
                  />
                ))}
            </Box>
            <Box
              flexShrink={0}
              w="100%"
              px={[3, null, null, 5]}
              py="8"
              display="flex"
              flexDir="column"
              alignItems="start"
            >
              {extraMenuCmp &&
                extraMenu?.map((item, index) => (
                  <SubMenuItem
                    key={index}
                    index={index}
                    page={menuPage}
                    component={extraMenuCmp}
                    onClose={close}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* RESERVATION */}

      <ReservationDrawer
        isOpen={isReservationOpen}
        onClose={closeReservation}
      />
    </>
  );
};

type MenuItemProps = {
  page: KustomPage;
  component: KustomPageComponent<CustomComponentData>;
  index: number;
  isActive: boolean;
  isInModal?: boolean;
  onClose?: () => void;
};

const MenuItem: React.FC<MenuItemProps> = ({
  page,
  component,
  index,
  isActive,
  isInModal,
  onClose,
}) => {
  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${index}`,
  );

  const linkProps = useNavigationKustomHref(buttonBind?.value?.actionString);

  const { currentLang } = useContext(LanguageContext);

  const styles = isInModal
    ? {
        fontFamily: 'Basteleur',
        fontSize: ['32px', null, '48px', null, null, '60px'],
        color: 'brand.500',
        py: 3,
        px: [6, null, 10],
        lineHeight: 1.05,
        transition: 'color 0.2s',
        letterSpacing: '-0.02em',
        _hover: {
          color: 'brand.600',
        },
      }
    : {
        fontFamily: 'body',
        color: isActive ? 'brand.500' : 'white',
        fontSize: [null, null, '1rem', null, null, '1.15rem'],
        fontWeight: 'bold',
        py: 3,
        px: [null, null, null, '1.25vw'],
        _before: {
          content: '""',
          position: 'absolute',
          bottom: '0.4em',
          left: '1.25vw',
          right: '1.25vw',
          height: '2px',
          bgColor: 'brand.500',
          transition: 'transform 0.3s ease-in-out',
          transformOrigin: 'right',
          transform: `scaleX(0)`,
        },
        _hover: {
          _before: {
            transformOrigin: 'left',
            transform: `scaleX(1)`,
          },
        },
      };

  return (
    <Box {...styles} position={'relative'} cursor="pointer">
      <Link {...linkProps} onClick={onClose}>
        {buttonBind?.value?.label[currentLang || 'fr']}
      </Link>
    </Box>
  );
};

interface SubMenuItemProps {
  index: number;
  page: KustomPage;
  component: KustomPageComponent<CustomComponentData>;
  onClose: () => void;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({
  page,
  component,
  index,
  onClose,
}) => {
  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${index}`,
  );

  const linkProps = useNavigationKustomHref(buttonBind?.value?.actionString);

  const beforeMenuItemStyle = {
    _before: {
      content: '""',
      position: 'absolute',
      bottom: '0.4em',
      left: 5,
      right: 5,
      height: '2px',
      bgColor: 'brand.500',
      transform: 'scaleX(0)',
      transition: 'transform 0.3s ease-in-out',
      transformOrigin: 'right',
    },
  };

  const hoveredMenuItemStyle = {
    _before: {
      transform: 'scaleX(1)',
      transformOrigin: 'left',
    },
  };

  return (
    <Link {...linkProps} onClick={onClose}>
      <Box role="group">
        <TranslatedString
          position={'relative'}
          color={'brand.500'}
          py="0.4rem"
          px={5}
          fontFamily="body"
          cursor="pointer"
          fontWeight="bold"
          fontSize="1rem"
          {...beforeMenuItemStyle}
          _groupHover={hoveredMenuItemStyle}
        >
          {buttonBind?.value?.label}
        </TranslatedString>
      </Box>
    </Link>
  );
};

export default Menu;
